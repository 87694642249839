.image {
  position: relative;
  &.lazy-load {
    &.loaded {
      img {
        display: block;
      }
    }
    img {
      display: none;
    }
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.carousel-control {
  cursor: pointer;
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}

.carousel {
  .image {
    width: 100%;
  }
  .flickity-prev-next-button {
    &,
    &:hover {
      background: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .flickity-page-dots {
    .dot {
      width: 16px;
      height: 16px;
      background: none;
      opacity: 1;
      margin: 0 6px;
      border: 1px solid;
      color: $white;
      &.is-selected {
        color: $black;
      }
      &:hover {
        background: currentColor;
      }
    }
  }

  // only show first until flickity enabled
  // > * {
  //   visibility: hidden;
  // }
  // > *:first-child,
  // > .flickity-viewport {
  //   visibility: visible;
  // }
}
