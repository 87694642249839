header#header {
  .nav-wrap {
    li {
      list-style: none;
      @include sohne(44px, $mobile-font-max: 24px);
      line-height: 1.1;
      text-align: center;

      @include desktop-only {
        @media screen and (max-height: 900px) {
          @include sohne(31px);
          line-height: 1.1;
        }
        @media screen and (min-height: 901px) and (max-height: 1414px) {
          @include sohne(42px);
          line-height: 1.1;
        }
        // iPad pro
        @media screen and (max-width: 1024px) {
          @include sohne(30px);
          line-height: 1.1;
        }
        @media screen and (min-height: 1415px) {
          @include sohne(40px);
          line-height: 1.1;
        }
      }
      a {
        color: $black;
        text-decoration: none;
        border: 2px solid transparent;
        pointer-events: all;

        &.home {
          .no-touchevents &:hover,
          &.flipped {
            :nth-child(1) {
              visibility: hidden;
            }
            :nth-child(2) {
              display: inline-block;
            }
          }
          .no-touchevents &.flipped:hover {
            :nth-child(1) {
              visibility: inherit;
            }
            :nth-child(2) {
              display: none;
            }
          }
          :nth-child(2) {
            display: none;
          }
        }

        // &:not(.home) {
        //   &:hover,
        //   &.current {
        //     border-color: $black;
        //   }
        // }
        .no-touchevents &:hover,
        &.child-current {
          border-color: $black;
        }
        @include mobile-only {
          &.current {
            border-color: $black;
          }
        }
        &.work {
          .state-menu-hover-projects & {
            border-color: $black;
          }
          .state-menu-hover-studio &,
          .state-menu-hover-home & {
            border-color: transparent;
          }
        }
        &.studio {
          .state-menu-hover-studio & {
            border-color: $black;
          }
          .state-menu-hover-projects &,
          .state-menu-hover-home & {
            border-color: transparent;
          }
        }
      }
    }
  }
  section.menu {
    a {
      pointer-events: none;
    }
  }
  @include desktop-only {
    &:before {
      position: fixed;
      width: 100%;
      top: -80px;
      left: 0;
      height: #{$menu-button-height + 80px};
      content: '';
      background: $nav-background;
      z-index: 60;

      @media screen and (max-height: 900px) {
        height: #{$menu-button-height-900 + 80px};
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        height: #{$menu-button-height-1300 + 80px};
      }
      @media screen and (max-width: 1024px) {
        height: #{$menu-button-height-1024 + 80px};
      }
      @media screen and (min-height: 1415px) {
        height: #{$menu-button-height-1415 + 80px};
      }
    }
    &:after {
      position: fixed;
      width: #{$header-contact-width + 1px};
      top: -80px;
      right: 0;
      height: #{$menu-button-height + 80px};
      content: '';
      background: $nav-background;
      z-index: 110;

      @media screen and (max-height: 900px) {
        height: #{$menu-button-height-900 + 80px};
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        height: #{$menu-button-height-1300 + 80px};
      }
      @media screen and (max-width: 1024px) {
        height: #{$menu-button-height-1024 + 80px};
      }
      @media screen and (min-height: 1415px) {
        height: #{$menu-button-height-1415 + 80px};
      }
      @media screen and (min-width: 2200px) {
        width: #{$header-contact-width-2200 + $scrollbar-width + 1px};
      }
    }
    .nav-wrap {
      position: fixed;
      display: inline-block;
      vertical-align: top;
      width: calc(((100vw) / #{$total-cols}) * 9.75);
      top: #{$menu-button-height / 2};
      left: 0;
      z-index: 61;
      transform: translateY(-50%);

      .state-studio-open &,
      .state-project-detail-open & {
        width: calc((100% / #{$total-cols}) * 9.75);
      }

      @media screen and (max-height: 900px) {
        top: #{$menu-button-height-900 / 2};
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        top: #{$menu-button-height-1300 / 2};
      }
      @media screen and (max-width: 1024px) {
        top: #{$menu-button-height-1024 / 2};
      }
      @media screen and (min-height: 1415px) {
        top: #{$menu-button-height-1415 / 2};
      }

      // @include desktop-small-only {
      //   top: 20px;
      // }
      // @include desktop-small-height-only {
      //   top: 20px;
      // }
      .state-studio-open &,
      .state-project-detail-open & {
        z-index: 65;
      }
      .state-menu-hover &,
      .no-touchevents &:hover {
        z-index: 65;
      }
      nav {
        display: block;

        li {
          @include ib-grid(3, 9.75);
          @include suffix(0.25, 9.75);

          &:first-child {
            @include prefix(0.25, 9.75);
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            position: relative;
            display: block;
            padding: 13px percentage(0.5 / 3);

            @media screen and (max-height: 900px) {
              padding: 7px percentage(0.5 / 3);
            }
            @media screen and (min-height: 901px) and (max-height: 1414px) {
              padding: 10px percentage(0.5 / 3);
            }
            @media screen and (max-width: 1024px) {
              padding: 8px percentage(0.5 / 3);
            }
            @media screen and (min-width: 2200px) {
              padding: 12px percentage(0.5 / 3);
            }
            //
            // @include desktop-small-only {
            //   padding: 8px percentage(0.5 / 3);
            // }
            // @include desktop-small-height-only {
            //   padding: 8px percentage(0.5 / 3);
            // }

            :nth-child(2) {
              position: absolute;
              width: 100%;
              left: 0;
              text-align: center;
              display: none;
            }
          }
        }
      }
    }
    section.menu {
      position: fixed;
      width: 100%;
      height: 100%;
      padding: 0 percentage(1 / $total-cols);
      top: 0;
      left: 0;
      z-index: 100;
      pointer-events: none;

      .state-project-detail-open & {
        z-index: 5;
      }
      .state-menu-hover & {
        z-index: 100;
      }
      :not(.state-project-detail-open):not(.state-studio-open).state-footer-visible & {
        visibility: hidden;
      }
      a {
        position: absolute;
        display: inline-block;
        width: $menu-button-width;
        transform: translateY(-100%);
        will-change: transform;

        @media screen and (max-height: 900px) {
          width: $menu-button-width-900;
        }
        @media screen and (min-height: 901px) and (max-height: 1414px) {
          width: $menu-button-width-1300;
        }
        @media screen and (max-width: 1024px) {
          width: $menu-button-width-1024;
        }
        @media screen and (min-height: 1415px) {
          width: $menu-button-width-1415;
        }

        &.studio {
          top: $menu-button-height;
          left: percentage(12.5 / $total-cols);

          @media screen and (max-height: 900px) {
            top: 0;
            transform: translateY(0%);
          }
          @media screen and (min-height: 901px) and (max-height: 1414px) {
            top: 0;
            transform: translateY(0%);
          }
          // iPad pro
          @media screen and (max-width: 1024px) {
            top: 0;
            transform: translateY(0%);
          }
          @media screen and (min-height: 1415px) {
            top: 0;
            transform: translateY(0%);
          }

          // @include desktop-small-only {
          //   top: 0;
          //   transform: translateY(0%);
          // }
          // @include desktop-small-height-only {
          //   top: 0;
          //   transform: translateY(0%);
          // }
        }
        &.work {
          top: 78.40909091vh;
          left: percentage(1.5 / $total-cols);

          .state-project-detail-open & {
            visibility: hidden;
          }
          .state-project-detail-open.state-menu-hover-projects & {
            visibility: inherit;
          }
        }
        &.loaded {
          .placeholder-wrap {
            display: none;
          }
        }
        .gif-wrap {
          width: 100%;

          &.loaded:not(.gif-normal) {
            img {
              display: none;
            }
            .jsgif {
              display: block;
            }
          }
          .image {
            width: 100%;
          }
          .jsgif {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            canvas {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
        .placeholder-wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          overflow: hidden;

          .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
  @include mobile-only {
    .nav-wrap {
      background: $blue;
      position: fixed;
      width: 100%;
      padding: 12px 0 15px;
      top: 0;
      left: 0;
      z-index: 80;

      &:before {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 160px;
        content: '';
        background: $nav-background;
        z-index: 60;
        transform: translateY(-100%);
      }
      nav {
        display: block;

        li {
          @include ib-grid(6);

          a {
            position: relative;
            @include ib-grid(5, 6);
            @include prefix(.5, 6);
            @include suffix(.5, 6);
            display: block;
            padding: 10px percentage(0.25 / 6);

            :nth-child(2) {
              position: absolute;
              width: 100%;
              left: 0;
              text-align: center;
              display: none;
            }
          }
        }
      }
    }
    section.menu {
      position: fixed;
      width: 100%;
      height: 100%;
      padding: 0 percentage(1 / $total-cols);
      top: 0;
      left: 0;
      z-index: 100;
      pointer-events: none;
      display: block;

      .state-project-detail-open & {
        display: block;
      }
      .state-studio-content-visible &,
      .state-footer-visible & {
        visibility: hidden;
      }
      a {
        position: absolute;
        @include ib-grid(4);

        &.studio {
          top: 80px;
          right: 0;
        }
        &.work {
          top: 285px;
          left: 0;
        }
        .gif-wrap {
          display: none;
        }
        .placeholder-wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          overflow: hidden;

          .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

#header-contact {
  .text-wrap {
    @include sohne(14px);
    line-height: 1.25;
    background: $rose;

    @include desktop-small-height-only {
      @include sohne(12px);
      line-height: 1.25;
    }

    a {
      color: $black;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      .no-touchevents &:hover {
        border-bottom-color: $black;
      }
    }
  }
  @include desktop-only {
    position: fixed;
    width: 100%;
    height: $header-contact-width;
    top: $menu-button-height;
    left: 100%;
    @include rotated(90deg, $origin: left top);
    z-index: 100;
    will-change: transform;

    .state-studio-open &,
    .state-project-detail-open & {
      left: calc(100% - #{$scrollbar-width});
    }

    @media screen and (max-height: 900px) {
      top: $menu-button-height-900;
    }
    @media screen and (min-height: 901px) and (max-height: 1414px) {
      top: $menu-button-height-1300;
    }
    @media screen and (max-width: 1024px) {
      top: $menu-button-height-1024;
    }
    @media screen and (min-height: 1415px) {
      top: $menu-button-height-1415;
    }
    @media screen and (min-width: 2200px) {
      height: $header-contact-width-2200;
    }

    .state-ajax-page-loading &,
    .state-footer-reset & {
      transition: transform .5s ease;
    }

    .text-wrap {
      position: absolute;
      top: 0;
      width: auto;
      height: 100%;
      padding: 20px percentage(2.5/$total-cols) 40px 75px;

      a {
        display: block;
      }
      p {
        margin: 0;
      }
      .block {
        display: inline-block;
        vertical-align: top;
        margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @include mobile-only {
    display: none;
  }
}

.header-fake-scrollbar-wrap {
  @include desktop-only {
    position: fixed;
    right: -$scrollbar-width;
    top: -80px;
    width: $scrollbar-width;
    z-index: 200;
    display: none !important;
    height: 100%;

    @include scrollbar-track;

    @media screen and (max-height: 900px) {
      height: #{$menu-button-height-900 + 80px};
    }
    @media screen and (min-height: 901px) and (max-height: 1414px) {
      height: #{$menu-button-height-1300 + 80px};
    }
    @media screen and (max-width: 1024px) {
      height: #{$menu-button-height-1024 + 80px};
    }
    @media screen and (min-height: 1415px) {
      height: #{$menu-button-height-1415 + 80px};
    }

    .state-studio-open &,
    .state-project-detail-open & {
      right: 0;
    }
    .state-ajax-page-loading &,
    .state-studio-open &,
    .state-project-detail-open & {
      display: block;
    }
  }
  @include mobile-only {
    display: none;
  }
}

.fake-scrollbar-wrap {
  @include desktop-only {
    position: fixed;
    right: -$scrollbar-width;
    top: 0;
    width: $scrollbar-width;
    height: 100vh;
    z-index: 200;
    display: none;

    @include scrollbar-track;

    .state-studio-open &,
    .state-project-detail-open & {
      right: 0;
    }
    .state-ajax-page-loading &,
    .state-studio-transitioning &,
    .state-studio-transition-out &,
    .state-project-transition-in &,
    .state-project-transition-out &,
    .state-ajax-to-ajax & {
      display: block;
    }
  }
  @include mobile-only {
    display: none;
  }
}
