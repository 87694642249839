footer#footer {
  background: $dark-blue;

  @include desktop-only {
    height: 0px;
    overflow: hidden;
    visibility: hidden;
    padding: 0;

    .footer-inner {
      position: relative;
    }

    .state-projects-open &,
    .state-project-detail-open & {
      height: auto;
      padding: 135px 0 0;
      overflow: auto;
      visibility: inherit;
    }
  }
  @include mobile-only {
    display: none;

    .state-projects-open & {
      display: block;
    }
    .state-project-detail-open & {
      pointer-events: none;
    }
  }
}

.projects-az-wrap {
  h1 {
    @include mobile-only {
      @include sohne(40px, $mobile-font-max: 40px);

      span {
        border-bottom: 3px solid $black;
      }
    }
  }
  .project-listing {
    span {
      @include sohne(27px, $mobile-font-max: 20px);
      font-weight: 400;
      color: $white;
      text-decoration: none;
      letter-spacing: 0;
      cursor: pointer;

      .no-touchevents &:hover {
        color: $black;
      }
      @include mobile-only {
        color: $black;
        font-weight: 600;
      }
    }
  }
  .inner {
    canvas {
      pointer-events: none;
    }
  }
  @include desktop-only {
    position: relative;
    overflow: hidden;

    .state-projects-loading-more & {
      visibility: hidden;
    }
    .canvas-wrap {
      position: absolute;
      @include ib-grid(13.25);
      @include prefix(6.625);
      left: 0;
      top: 0;
      padding-top: 73.61111111%;
      pointer-events: none;
      transform: translate3d(-50%, 0, 0);

      &.fixed {
        position: fixed;
      }
      &.resizing {
        visibility: hidden;

        .state-ajax-page-loading & {
          visibility: inherit;
        }
      }
      .canvas-inner {
        position: absolute;
        width: 100%;
        padding-top: 100%;
        top: 0;
        left: 0;

        canvas {
          position: absolute;
          width: 100% !important;
          height: 100% !important;
          left: 0;
          top: 0;
          will-change: transform;

          &.fixed {
            position: fixed;
          }
        }
      }
    }
    .project-list-wrap {
      @include ib-grid(5.5);
      @include prefix(12);
      @include suffix(.5);
      padding: 50px 0 15px;
      min-height: 900px;

      .project-listing {
        &:last-child {
          span {
            padding-bottom: 0;
          }
        }
        span {
          display: inline-block;

          padding-bottom: 10px;
        }
        .images {
          display: none;
        }
      }
    }
  }
  @include mobile-only {
    position: relative;

    .canvas-wrap {
      display: none;
    }
    .project-list-wrap {
      @include ib-grid(16.5);
      @include prefix(.75);
      @include suffix(.75);
      padding: 80px 0 220px;
      min-height: calc(100vh - 79px);

      &:before,
      &:after {
        position: fixed;
        @include ib-grid(2);
        padding-top: percentage(2/$total-cols);
        background: $black;
        border-radius: 100%;
        content: '';
        z-index: 10;
        visibility: hidden;
        pointer-events: none;
      }
      &:before {
        left: percentage(1/$total-cols);
        top: 54vh;
      }
      &:after {
        right: percentage(3/$total-cols);
        top: 12.5vh;
      }
      .state-footer-visible & {
        &:before,
        &:after {
          visibility: inherit;
        }
      }

      .state-projects-loading-more & {
        visibility: hidden;
      }
      .project-listing {
        margin-bottom: 3px;

        &:last-child {
          margin-bottom: 0;
        }
        span {
          display: inline-block;
        }
        .images {
          display: none;
        }
      }
    }
  }
}
