@mixin lock-screen {
  width: 100% !important;
  height: calc(100vh - 1px) !important;
  overflow: hidden !important;
}

html {
  font-kerning: normal;
  -webkit-overflow-scrolling: touch;

  .state-cursor-show & {
    &,
    * {
      cursor: none !important;
    }
  }

  @include desktop-only {
    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }
    &::-webkit-scrollbar-track {
      @include scrollbar-track;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-thumb;
    }
    &.state-studio-open,
    &.state-project-detail-open {
      header#header {
        &:before {
          width: calc(100% - #{$scrollbar-width});
        }
        &:after {
          right: $scrollbar-width;
        }
      }
    }
    &.state-scrollbars-fixed.state-studio-open,
    &.state-scrollbars-fixed.state-project-detail-open {
      header#header {
        padding-right: 0;

        &:before {
          width: calc(100% - #{$scrollbar-width});
        }
        &:after {
          width: #{$header-contact-width + $scrollbar-width + 1px};
          right: $scrollbar-width;

          @media screen and (min-width: 2200px) {
            width: #{$header-contact-width-2200 + $scrollbar-width + 1px};
          }
        }
        section.menu {
          width: calc(100% - #{$scrollbar-width});
        }
        .nav-wrap {
          width: calc(100% / #{$total-cols} * 9.75);
        }
        #header-contact {
          left: calc(100% - #{$scrollbar-width});
        }
      }
      footer#footer {
        width: calc(100% - #{$scrollbar-width});
      }
      .projects-index {
        .index-inner,
        .detail-inner {
          width: calc(100vw - #{$scrollbar-width});
        }
      }
      .wadeandleta-project {
        .project-content-wrap {
          .text-wrap {
            position: relative;
          }
          .text-wrap,
          .image-list-wrap .bkg-wrap {
            &:before {
              position: absolute;
              width: #{$scrollbar-width};
              top: 0;
              left: 100%;
              height: 100%;
              content: '';
              background: inherit;
            }
            &:after {
              position: absolute;
              width: 100vw;
              top: 2px;
              left: 0;
              height: 4px;
              content: '';
              background: inherit;
            }
          }
        }
      }
      .wadeandleta-studio {
        .studio-inner {
          .bkg {
            width: calc(100% + #{$scrollbar-width});
          }
        }
      }
    }
    &.state-studio-open,
    &.state-project-detail-open {
      @include lock-screen;
    }
  }
  @include mobile-only {
    &.state-ajax-page-loading,
    &.state-studio-open,
    &.state-project-detail-open {
      @include lock-screen;
    }
  }
}

body {
  position: relative;
  background: $blue;
  visibility: hidden;
  overflow: hidden;
  pointer-events: none;

  &.state-initialised {
    visibility: inherit;
    pointer-events: inherit;
    overflow: inherit;
  }
  & > #content {
    > article {
      @include grid-container;
      height: 100%;
      min-height: 500px;
    }
  }
}

#cursor {
  position: fixed;
  top: 0;
  left: 0;
  @include ib-grid(.75);
  transform: translate3d(0, 0, 0);
  will-change: transform, visibility;
  visibility: hidden;
  z-index: 100;
  pointer-events: none;

  .state-cursor-show & {
    visibility: visible;
  }
  .state-project-detail-open &,
  .state-studio-open & {
    visibility: hidden;
  }
  img {
    transform: translate3d(-50%, -50%, 0);
    transition: transform .25s ease;

    .state-cursor-show-medium & {
      transform: translate3d(-50%, -50%, 0) scale(1.3333333333);
    }
    .state-cursor-show-large & {
      transform: translate3d(-50%, -50%, 0) scale(1.6666666667);
    }
  }
  @include mobile-only {
    display: none;
  }
}

.scrollbar-check-base,
.scrollbar-check-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
}
.scrollbar-check-base {
  width: 100vw;
}
.scrollbar-check-inner {
  width: 100%;
  max-height: 1px;
  overflow: scroll;
}

.no-desktop {
  @include desktop-only {
    display: none !important;
  }
}

.no-mobile {
  @include mobile-only {
    display: none !important;
  }
}
