@function smart-font-prop(
  $font-size,
  $lower-val,
  $lower-cutoff,
  $upper-val,
  $upper-cutoff
) {
  // Intelligently calculate a css property, so that it varies on a scale from
  // $upper-val to $lower-val depending on the font size:
  // - for font-size less than $lower-cutoff, line-height is $lower-val
  // - for font-size greater than $upper-cutoff, line-height is $upper-val
  // - in between these values, line-height scales up evenly from $upper-val to $lower-val

  @if $font-size > $upper-cutoff {
    @return $upper-val;
  } @else if $font-size > $lower-cutoff {
    @return $upper-val + ($lower-val - $upper-val) *
      ($upper-cutoff - $font-size) / ($upper-cutoff - $lower-cutoff);
  } @else {
    @return $lower-val;
  }
}

@mixin smart-font-size(
  $font-size,
  $lh-max: null,
  $lh-lower-cutoff: null,
  $lh-min: null,
  $lh-upper-cutoff: null,
  $ls-max: null,
  $ls-lower-cutoff: null,
  $ls-min: null,
  $ls-upper-cutoff: null,
  $font-resize-cutoff: 960px,
  $mobile-font-max: null
) {
  // Set font-size, ensuring that it is never larger than $mobile-font-max at
  // $mobile-screen-width, by scaling it down to there from
  // $font-resize-cutoff
  // Additionally, set line-height and letter-spacing via smart-font-prop

  font-size: $font-size;
  @if $lh-max {
    line-height: smart-font-prop(
      $font-size,
      $lh-max,
      $lh-lower-cutoff,
      $lh-min,
      $lh-upper-cutoff
    );
  }
  @if $ls-max {
    letter-spacing: smart-font-prop(
      $font-size,
      $ls-max,
      $ls-lower-cutoff,
      $ls-min,
      $ls-upper-cutoff
    );
  }
  $mobile-screen-width: 414px;
  @if not $mobile-font-max {
    $mobile-font-max: 32px;
  }

  @if $font-size > $mobile-font-max {
    @media (max-width: $mobile-screen-width) {
      font-size: $mobile-font-max;
      @if $lh-max {
        line-height: smart-font-prop(
          $mobile-font-max,
          $lh-max,
          $lh-lower-cutoff,
          $lh-min,
          $lh-upper-cutoff
        );
      }
      @if $ls-max {
        letter-spacing: smart-font-prop(
          $mobile-font-max,
          $ls-max,
          $ls-lower-cutoff,
          $ls-min,
          $ls-upper-cutoff
        );
      }
      // word-break: break-word;
    }
    @media (min-width: #{$mobile-screen-width + 1}) and (max-width: #{$font-resize-cutoff}) {
      $ratio: ($font-size - $mobile-font-max) /
        ($font-resize-cutoff - $mobile-screen-width);
      font-size: calc(
        #{$ratio * 100vw} + #{$mobile-font-max - $ratio * $mobile-screen-width}
      );

      // just set line-height in the middle of the range to avoid overly
      // complex calculations
      $font-size-midpoint: ($font-size + $mobile-font-max) / 2;
      @if $lh-max {
        line-height: smart-font-prop(
          $font-size-midpoint,
          $lh-max,
          $lh-lower-cutoff,
          $lh-min,
          $lh-upper-cutoff
        );
      }
      @if $ls-max {
        letter-spacing: smart-font-prop(
          $font-size-midpoint,
          $ls-max,
          $ls-lower-cutoff,
          $ls-min,
          $ls-upper-cutoff
        );
      }
    }
  }
}
