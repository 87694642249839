article.projects-index {
  @include desktop-only {
    .artwork-list {
      position: absolute;
      width: 100%;
      top: 0;
      padding: $menu-button-height 0 0;
      pointer-events: none;

      @media screen and (max-height: 900px) {
        padding-top: $menu-button-height-900;
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        padding-top: $menu-button-height-1300;
      }
      @media screen and (max-width: 1024px) {
        padding-top: $menu-button-height-1024;
      }
      @media screen and (min-height: 1415px) {
        padding-top: $menu-button-height-1415;
      }

      .state-projects-open & {
        display: none;
      }
      article.artwork-listing {
        &.portrait {
          &.size-s {
            @include cols(9);
            margin: 0 0 percentage(3.5 / $total-cols);

            &.align-l {
              margin-left: 0;
            }
            &.align-m {
              margin-left: percentage(5 / $total-cols);
            }
            &.align-r {
              margin-left: calc((100% / #{$total-cols} * 9) - #{$header-contact-width});

              @media screen and (min-width: 2200px) {
                margin-left: calc((100% / #{$total-cols} * 9) - #{$header-contact-width-2200});
              }
            }
          }
          &.size-m {
            @include cols(9.25);
            margin: 0 0 percentage(4 / $total-cols);

            &.align-l {
              margin-left: 0;
            }
            &.align-m {
              margin-left: percentage(3.75 / $total-cols);
            }
            &.align-r {
              margin-left: calc((100% / #{$total-cols} * 8.75) - #{$header-contact-width});

              @media screen and (min-width: 2200px) {
                margin-left: calc((100% / #{$total-cols} * 8.75) - #{$header-contact-width-2200});
              }
            }
          }
          &.size-l {
            @include cols(15.5);
            margin: 0 0 percentage(5.75 / $total-cols);

            &.align-l {
              margin-left: percentage(.75 / $total-cols);
            }
            &.align-m,
            &.align-r {
              margin-left: calc((100% / #{$total-cols} * 2.5) - #{$header-contact-width});

              @media screen and (min-width: 2200px) {
                margin-left: calc((100% / #{$total-cols} * 2.5) - #{$header-contact-width-2200});
              }
            }
          }
          &:first-child {
            &.size-s {
              margin-left: calc((100% / #{$total-cols} * 9) - #{$header-contact-width}) !important;

              @media screen and (min-width: 2200px) {
                margin-left: calc((100% / #{$total-cols} * 9) - #{$header-contact-width-2200}) !important;
              }
            }
            &.size-m {
              margin-left: calc((100% / #{$total-cols} * 8.75) - #{$header-contact-width}) !important;
              margin-bottom: percentage(5 / $total-cols);

              @media screen and (min-width: 2200px) {
                margin-left: calc((100% / #{$total-cols} * 8.75) - #{$header-contact-width-2200}) !important;
              }
            }
            &.size-l.align-l {
              margin-left: calc((100% / #{$total-cols} * 2.5) - #{$header-contact-width});

              @media screen and (min-width: 2200px) {
                margin-left: calc((100% / #{$total-cols} * 2.5) - #{$header-contact-width-2200});
              }
            }
          }
        }
        &.landscape {
          &.size-s {
            @include cols(10);
            margin: 0 0 percentage(3.5 / $total-cols);

            &.align-l {
              margin-left: percentage(3 / $total-cols);
            }
            &.align-m {
              margin-left: percentage(7 / $total-cols);
            }
            &.align-r {
              margin-left: percentage(13 / $total-cols);
            }
          }
          &.size-m {
            @include cols(14);
            margin: 0 0 percentage(3.5 / $total-cols);

            &.align-l {
              margin-left: 0;
            }
            &.align-m {
              margin-left: percentage(3 / $total-cols);
            }
            &.align-r {
              margin-left: percentage(10 / $total-cols);
            }
          }
          &.size-l {
            @include cols($total-cols);
            margin: 0 0 percentage(3.5 / $total-cols);

            &.align-l {
              @include cols(22);
              margin-left: 0;
            }
            &.align-m {
              margin-left: 0;
            }
            &.align-r {
              @include cols(22);
              margin-left: percentage(2 / $total-cols);
            }
          }
          &:first-child {
            &.size-s {
              &.align-r {
                margin-left: calc((100% / #{$total-cols} * 14) - #{$header-contact-width});

                @media screen and (min-width: 2200px) {
                  margin-left: calc((100% / #{$total-cols} * 14) - #{$header-contact-width-2200});
                }
              }
            }
            &.size-m {
              &.align-r {
                margin-left: calc((100% / #{$total-cols} * 10) - #{$header-contact-width});

                @media screen and (min-width: 2200px) {
                  margin-left: calc((100% / #{$total-cols} * 10) - #{$header-contact-width-2200});
                }
              }
            }
            &.size-l {
              &.align-m {
                width: calc(100% - #{$header-contact-width});

                @media screen and (min-width: 2200px) {
                  width: calc(100% - #{$header-contact-width-2200});
                }
              }
              &.align-r {
                margin-left: calc((100% / #{$total-cols} * 2) - #{$header-contact-width});

                @media screen and (min-width: 2200px) {
                  margin-left: calc((100% / #{$total-cols} * 2) - #{$header-contact-width-2200});
                }
              }
            }
          }
        }
        .artwork-inner {
          position: relative;

          .artwork-body {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 30;

            .state-studio-open & {
              z-index: 12;
            }
            .video-player {
              video {
                width: calc(100% + 2px);
              }
            }
            .image {
              img {
                width: calc(100% + 2px);
              }
            }
          }
        }
      }
    }
  }
  @include mobile-only {
    padding-top: $mobile-menu-button-height;

    .artwork-list {
      position: absolute;
      width: 100%;
      top: 0;
      padding: percentage(2.5 / $total-cols) 0 0;

      .state-projects-open & {
        display: none;
      }
      article.artwork-listing {
        &.portrait {
          @include ib-grid(16);
          margin-bottom: percentage(8 / $total-cols);

          &.size-m {
            &.align-r {
              @include prefix(2);
            }
          }
          &.size-l {
            @include ib-grid(18);
          }
        }
        &.landscape {
          @include ib-grid(18);
          margin-bottom: percentage(8 / $total-cols);
        }
        .artwork-inner {
          position: relative;

          .artwork-body {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 40;

            video {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
