article.projects-index {
  .project-listing {
    a {
      .state-cursor-show & {
        cursor: none !important;
      }
    }
    .ticker-tape {
      pointer-events: none;

      span {
        @include sohne(18px);
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    article.carousel-cell {
      .text-wrap {
        .title-wrap {
          h1 {
            @include sohne(32px, $mobile-font-max: 20px);
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
          }
        }
        .block {
          p {
            @include sohne(18px, $mobile-font-max: 16px);
          }
        }
        .featured {
          p {
            @include sohne(32px, $mobile-font-max: 20px);
          }
        }
        .year {
          text-align: right;

          h1 {
            @include sohne(200px, $mobile-font-max: 40px);
            line-height: 0.8;
            font-weight: normal;
            text-align: right;
          }
        }
      }
    }
  }
  @include desktop-only {
    $project-transition: 0.5s;

    .state-project-detail-open & {
      pointer-events: none;
    }
    .index-inner {
      position: relative;

      .project-list {
        padding: percentage(17 / $total-cols) 0 600px;

        article.project-listing {
          pointer-events: none;

          .state-projects-open & {
            pointer-events: all;
          }
          &.detail-visible {
            .ticker-tape {
              z-index: 80;
            }
          }
          &.show-more {
            .ticker-tape {
              @include transform(rotate(-90deg) translateY(-100%));
            }
            a.link {
              @include transform(translate3d(-50%, 100%, 0));
            }
          }
          .project-wrap {
            position: relative;

            .bkg {
              position: absolute;
              width: 100%;
              height: calc(100% - 1px);
              top: 0;
              left: 0;
              z-index: 9;
            }
          }
          .project-inner {
            position: relative;

            .project-body {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              z-index: 10;
              opacity: 0;

              .state-home-to-work & {
                transition: opacity .4s ease;
              }
              .state-projects-open & {
                opacity: 1;
              }
              .state-home-to-work.state-projects-open & {
                opacity: 0;
              }
              .state-home-to-work.state-projects-open.state-projects-settled & {
                opacity: 1;
              }
              .image-wrap {
                position: relative;

                &.pre-loaded {
                  .bkg-img {
                    .state-home-to-work & {
                      transition: opacity .4s ease;
                    }
                  }
                }
                &.loaded {
                  .bkg-img {
                    .state-projects-open & {
                      opacity: 0;
                    }
                    .state-home-to-work.state-projects-open & {
                      opacity: 1;
                    }
                    .state-home-to-work.state-projects-open.state-projects-settled & {
                      opacity: 0;
                    }
                  }
                  .image {
                    img {
                      display: block;
                    }
                  }
                }
                .bkg-img {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 10;
                  opacity: 1;
                  pointer-events: none;
                }
                .image {
                  .state-page-resizing & {
                    visibility: hidden;
                  }
                  .state-studio-transitioning &,
                  .state-project-transition-in &,
                  .state-project-transition-out & {
                    visibility: inherit;
                  }
                }
                .videoplayer-inner .large-playpause {
                  z-index: 10;
                }
                .videoplayer-inner .video-controls {
                  .volume-bar,
                  .fs {
                    display: none !important;
                  }
                }
                .video-player {
                  &,
                  * {
                    cursor: none !important;
                  }
                }
                video {
                  pointer-events: none;

                  .state-page-resizing & {
                    visibility: hidden;
                  }
                  .state-studio-transitioning &,
                  .state-project-transition-in &,
                  .state-project-transition-out & {
                    visibility: inherit;
                  }
                }
              }
            }
          }
          .ticker-tape {
            position: absolute;
            width: 100%;
            top: 100%;
            overflow: hidden;
            @include transform(rotate(-90deg));
            @include transform-origin(top left);
            @include transition(#{transform $project-transition});
            -webkit-backface-visibility: hidden;

            & > .item {
              position: absolute;
              right: 100%;
            }
            .ticker-tape-inner {
              padding: 12px 0;
              white-space: nowrap;
            }
          }
          &.priority {
            .project-body {
              z-index: 11;
            }
          }
          &.portrait {
            .project-wrap {
              @include cols(9.25);
              margin: 0 0 percentage(7 / $total-cols);
            }
            &.padded {
              margin: 0 0 percentage(10 / $total-cols);

              .project-wrap {
                margin: 0 percentage(1 / $total-cols);
              }
              &.left {
                .project-wrap {
                  margin-left: percentage(1 / $total-cols);
                }
              }
              &.right {
                .project-wrap {
                  margin-left: calc((100% / #{$total-cols} * 8) - #{$header-contact-width});

                  @media screen and (min-width: 2200px) {
                    margin-left: calc((100% / #{$total-cols} * 8) - #{$header-contact-width-2200});
                  }
                }
              }
            }
            &.left {
              .project-wrap {
                margin-left: 0;
              }
            }
            &.right {
              .project-wrap {
                margin-left: calc((100% / #{$total-cols} * 8.75) - #{$header-contact-width});

                @media screen and (min-width: 2200px) {
                  margin-left: calc((100% / #{$total-cols} * 8.75) - #{$header-contact-width-2200});
                }
              }
            }
            &.top {
              margin-top: 0;
            }
            &.bottom {
              margin-bottom: 0;
            }
          }
          &.landscape {
            .project-wrap {
              width: calc(100% - #{$header-contact-width});
              margin: 0 0 calc((100vw / #{$total-cols}) * 5.5);

              @media screen and (min-width: 2200px) {
                width: calc(100% - #{$header-contact-width-2200});
              }
            }
            &.padded {
              .project-wrap {
                padding: 110px percentage(1 / $total-cols);
              }
            }
          }
        }
      }
    }
  }
  @include mobile-only {
    $project-transition: 0.5s;
    padding-top: $mobile-menu-button-height;

    .state-project-detail-open & {
      pointer-events: none;
    }
    .index-inner {
      position: relative;

      .project-list {
        article.project-listing {
          pointer-events: none;

          &:last-child {
            .project-wrap {
              margin-bottom: 0 !important;
            }
          }
          .state-projects-open & {
            pointer-events: all;
          }
          .state-project-detail-open & {
            pointer-events: none;
          }
          &.portrait {
            .project-wrap {
              @include ib-grid(16);
              margin-bottom: percentage(5.5/$total-cols);
            }
            &.left {
              .project-wrap {
                @include ib-grid(12);
                @include prefix(0);
                margin-bottom: percentage(3/$total-cols);
              }
            }
            &.right {
              .project-wrap {
                @include prefix(2);
              }
            }
          }
          &.landscape {
            .project-wrap {
              margin-bottom: percentage(5.5/$total-cols);
            }
            &.padded {
              .project-wrap {
                padding: percentage(1 / $total-cols) percentage(.75 / $total-cols);
              }
            }
          }
          .ticker-tape {
            display: none;
          }
          .project-wrap {
            position: relative;

            .bkg {
              position: absolute;
              width: 100%;
              height: calc(100% - 1px);
              top: 0;
              left: 0;
              z-index: 9;
            }
          }
          .project-inner {
            position: relative;

            .project-body {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              z-index: 10;
              opacity: 0;

              .state-home-to-work & {
                transition: opacity .4s ease;
              }
              .state-projects-open & {
                opacity: 1;
              }
              .state-home-to-work.state-projects-open & {
                opacity: 0;
              }
              .state-home-to-work.state-projects-open.state-projects-settled & {
                opacity: 1;
              }
              .image-wrap {
                position: relative;

                &.pre-loaded {
                  .bkg-img {
                    .state-home-to-work & {
                      transition: opacity .4s ease;
                    }
                  }
                }
                &.loaded {
                  .bkg-img {
                    .state-projects-open & {
                      opacity: 0;
                    }
                    .state-home-to-work.state-projects-open & {
                      opacity: 1;
                    }
                    .state-home-to-work.state-projects-open.state-projects-settled & {
                      opacity: 0;
                    }
                  }
                  .image {
                    img {
                      display: block;
                    }
                  }
                }
                .bkg-img {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: 10;
                  opacity: 1;
                  pointer-events: none;
                }
                .image {
                  .state-studio-transitioning &,
                  .state-project-transition-in &,
                  .state-project-transition-out & {
                    visibility: inherit;
                  }
                }
                .videoplayer-inner .large-playpause {
                  z-index: 10;
                }
                .videoplayer-inner .video-controls {
                  .volume-bar,
                  .fs {
                    display: none !important;
                  }
                }
                video {
                  pointer-events: none;

                  .state-page-resizing & {
                    visibility: hidden;
                  }
                  .state-studio-transitioning &,
                  .state-project-transition-in &,
                  .state-project-transition-out & {
                    visibility: inherit;
                  }
                }
              }
            }
          }
        }

      //   article.project-listing {
      //     .project-wrap {
      //       position: relative;
      //
      //       .bkg {
      //         position: absolute;
      //         width: 100%;
      //         height: 100%;
      //         top: 0;
      //         left: 0;
      //         z-index: 9;
      //       }
      //     }
      //     .project-inner {
      //       position: relative;
      //
      //       .project-body {
      //         display: none;
      //         position: absolute;
      //         width: 100%;
      //         height: 100%;
      //         top: 0;
      //         z-index: 10;
      //
      //         .state-projects-open & {
      //           display: block;
      //         }
      //       }
      //     }
      //     .ticker-tape {
      //       display: none;
      //     }
      //     &:hover {
      //       &,
      //       &.priority {
      //         .project-body {
      //           z-index: 30;
      //         }
      //       }
      //     }
      //     &.priority {
      //       .project-body {
      //         z-index: 11;
      //       }
      //     }
      //     &.portrait {
      //       .project-wrap {
      //         @include cols(22);
      //         margin: 0 auto percentage(10 / $total-cols);
      //       }
      //       &.top {
      //         margin-top: 0;
      //       }
      //       &.bottom {
      //         margin-bottom: 0;
      //       }
      //     }
      //     &.landscape {
      //       .project-wrap {
      //         @include cols($total-cols);
      //         margin: 0 0 percentage(10 / $total-cols);
      //       }
      //
      //       &.padded {
      //         .project-wrap {
      //           padding: percentage(2 / $total-cols);
      //         }
      //       }
      //     }
      //   }
      }
    }
  }
}

.header-project-spacer {
  @include desktop-only {
    position: fixed;
    width: 100%;
    z-index: 50;
    background: $blue;
    height: calc(100vh - #{$menu-button-height});
    top: $menu-button-height;
    left: 0;
    display: none;

    .state-studio-open & {
      background: $dark-blue;
    }

    @media screen and (max-height: 900px) {
      top: $menu-button-height-900;
      height: calc(100vh - #{$menu-button-height-900});
    }
    @media screen and (min-height: 901px) and (max-height: 1414px) {
      top: $menu-button-height-1300;
      height: calc(100vh - #{$menu-button-height-1300});
    }
    @media screen and (max-width: 1024px) {
      top: $menu-button-height-1024;
      height: calc(100vh - #{$menu-button-height-1024});
    }
    @media screen and (min-height: 1415px) {
      top: $menu-button-height-1415;
      height: calc(100vh - #{$menu-button-height-1415});
    }

    .state-studio-open & {
      display: block;
    }
  }
  @include mobile-only {
    display: none;
  }
}

.wadeandleta-project {
  @include desktop-only {
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
    z-index: 50;
    overflow: hidden;

    padding-top: $menu-button-height;

    @media screen and (max-height: 900px) {
      padding-top: $menu-button-height-900;
    }
    @media screen and (min-height: 901px) and (max-height: 1414px) {
      padding-top: $menu-button-height-1300;
    }
    @media screen and (max-width: 1024px) {
      padding-top: $menu-button-height-1024;
    }
    @media screen and (min-height: 1415px) {
      padding-top: $menu-button-height-1415;
    }

    padding-right: $scrollbar-width;

    &.scroll {
      .state-project-detail-open & {
        overflow-y: auto;
        padding-right: 0;
      }
      &::-webkit-scrollbar {
        width: $scrollbar-width;
      }
      &::-webkit-scrollbar-track {
        @include scrollbar-track;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
      }
    }
  }
  @include mobile-only {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
    overflow: hidden;

    #content & {
      height: 100vh;
      min-height: auto;
    }
    &.scroll {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .project-content-wrap {
    h2 {
      @include sohne(50px, $mobile-font-max: 22px);
      font-weight: 400;
    }
    h5 {
      @include mobile-only {
        @include sohne(13px);
      }
    }
    .description-wrap {
      @include sohne(28px, $mobile-font-max: 16px);
      line-height: 1.18;
    }
    @include desktop-only {
      position: relative;

      .text-wrap {
        padding-top: 50px;
        padding-bottom: 210px;

        &:before {
          position: absolute;
          top: 0;
          right: 100%;
          height: 100%;
          content: '';
          width: #{$scrollbar-width};
        }
        .title-wrap {
          @include ib-grid(12);
          @include prefix(1);

          h2 {
            margin: 0;
          }
        }
        .description-wrap {
          @include ib-grid(8.75);
          @include prefix(6.75);
          margin-top: 60px;
        }
      }
      .image-list-wrap {
        position: relative;

        .bkg-wrap {
          position: absolute;
          width: 100%;
          height: calc(100% - 1px);
          top: 0;
          left: 0;
          z-index: -1;
        }
        .image-listing {
          width: calc(100% - #{$header-contact-width});

          @media screen and (min-width: 2200px) {
            width: calc(100% - #{$header-contact-width-2200});
          }

          &.a-left,
          &.a-right {
            @include ib-grid(9.25);
          }
          &.a-center {
            @include ib-grid(14);
            margin-left: calc(((100% / #{$total-cols}) * 2) - #{$header-contact-width / 2});
            margin-right: calc(((100% / #{$total-cols}) * 2) - #{$header-contact-width / 2});

            @media screen and (min-width: 2200px) {
              margin-left: calc(((100% / #{$total-cols}) * 2) - #{$header-contact-width-2200 / 2});
              margin-right: calc(((100% / #{$total-cols}) * 2) - #{$header-contact-width-2200 / 2});
            }
          }
          &.a-left {
            @include suffix(8.75);
          }
          &.a-right {
            margin-left: calc(((100% / #{$total-cols}) * 8.75) - #{$header-contact-width});

            @media screen and (min-width: 2200px) {
              margin-left: calc(((100% / #{$total-cols}) * 8.75) - #{$header-contact-width-2200});
            }
          }
          &.no-controls {
            .image-wrap {
              .video-player {
                pointer-events: none;
              }
            }
          }
          .image-wrap {
            &.loaded {
              .image {
                img {
                  display: block;
                }
              }
            }
            .image {
              overflow: hidden;

              .state-page-resizing & {
                visibility: hidden;
              }
              .state-studio-transitioning &,
              .state-project-transition-in &,
              .state-project-transition-out & {
                visibility: inherit;
              }
              img {
                width: calc(100% + 2px);
              }
            }
          }
          .videoplayer-inner .large-playpause {
            z-index: 10;
          }
          .videoplayer-inner .video-controls {
            .volume-bar,
            .fs {
              display: none !important;
            }
          }
          img,
          video {
            .state-page-resizing & {
              visibility: hidden;
            }
            .state-studio-transitioning &,
            .state-project-transition-in &,
            .state-project-transition-out & {
              visibility: inherit;
            }
          }
        }
      }
      .projects-az-wrap {
        background: $dark-blue;
        padding-top: 125px;
      }
    }
    @include mobile-only {
      position: relative;

      .text-wrap {
        padding-top: #{$mobile-menu-button-height + 55px};
        padding-bottom: 60px;

        .title-wrap {
          @include ib-grid(15);
          @include prefix(1.5);
          @include suffix(1.5);
          text-align: center;

          h2 {
            margin: 0;
          }
        }
        .description-wrap {
          @include ib-grid(15);
          @include prefix(1.5);
          @include suffix(1.5);
          margin-top: 45px;
          margin-bottom: 25px;
        }
      }
      .image-list-wrap {
        position: relative;
        padding-bottom: 80px;

        .bkg-wrap {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -1;
        }
        .image-listing {
          &.a-left,
          &.a-right {
            @include ib-grid(15.5);
            margin-top: percentage(3/$total-cols);
            margin-bottom: percentage(3.5/$total-cols);
          }
          &.a-left {
            @include suffix(2.5);
          }
          &.a-right {
            @include prefix(2.5);
          }
          &.no-controls {
            .image-wrap {
              .video-player {
                pointer-events: none;
              }
            }
          }
          .image-wrap {
            &.loaded {
              .image {
                img {
                  display: block;
                }
              }
            }
            .image {
              .state-studio-transitioning &,
              .state-project-transition-in &,
              .state-project-transition-out & {
                visibility: inherit;
              }
            }
          }
          .videoplayer-inner .large-playpause {
            z-index: 10;
          }
          .videoplayer-inner .video-controls {
            .volume-bar,
            .fs {
              display: none !important;
            }
          }
          img,
          video {
            .state-studio-transitioning &,
            .state-project-transition-in &,
            .state-project-transition-out & {
              visibility: inherit;
            }
          }
        }
      }
      .projects-az-wrap {
        background: $dark-blue;
      }
    }
  }
}
