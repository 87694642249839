body,
table,
input,
textarea,
select {
  @include body-type;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;

  &.mce-content-body {
    font-size: $body-font-size;
  }
}

strong {
  font-weight: 500;
}

.text {
  :last-child {
    margin-bottom: 0;
  }
}
