@import "../lib/type_mixins";

@mixin sohne(
  $font-size: null,
  $font-resize-cutoff: 960px,
  $mobile-font-max: 40px
) {
  font-family: "Sohne", "Helvetica", sans-serif;

  @if $font-size {
    @include smart-font-size(
      $font-size,
      $lh-max: 1.4,
      $lh-lower-cutoff: 14px,
      $lh-min: 1.1,
      $lh-upper-cutoff: 30px,
      $ls-max: -0px,
      $ls-lower-cutoff: 11px,
      $ls-min: -0px,
      $ls-upper-cutoff: 60px,
      $font-resize-cutoff: $font-resize-cutoff,
      $mobile-font-max: $mobile-font-max
    );
  }
}

// these mixins define reusable groups of font-related styles, and should be
// used where possible to keep type styles consistent around the site
//
// mixin names should describe the appearance, not the usage, i.e.
// "national-large-heading" not "bulletin-article-heading"

$body-font-size: 16px;

@mixin body-type {
  @include sohne($body-font-size);
}

@mixin text-link {
  @include underline;

  .no-touchevents &:hover,
  &.current {
    @include no-underline;
  }
}

@mixin stealth-link {
  @include no-underline;

  .no-touchevents &:hover,
  &.current {
    @include underline;
  }
}
