@mixin transform($val) {
  transform: $val;
  -webkit-transform: $val;
  -moz-transform: $val;
  -ms-transform: $val;
}

@mixin transform-origin($origin, $origin: center center) {
  -moz-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin rotated($deg, $origin: center center) {
  -moz-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);

  @include transform-origin($origin);
}

@mixin rounded($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin transition($val, $prefixed: false) {
  @if $prefixed {
    -moz-transition: -moz-#{$val};
    -webkit-transition: -webkit-#{$val};
  } @else {
    -moz-transition: $val;
    -webkit-transition: $val;
  }
  transition: $val;
}

@mixin greyscale($amount) {
  -webkit-filter: grayscale($amount * 100%);
  -moz-filter: grayscale($amount * 100%);
  -ms-filter: grayscale($amount * 100%);
  -o-filter: grayscale($amount * 100%);
  filter: grayscale($amount * 100%);
  // @if $amount {
  //     filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  //     filter: gray;
  // }
  // else {
  //     filter: none;
  // }
}

@mixin animation($val) {
  -o-animation: $val;
  -moz-animation: $val;
  -webkit-animation: $val;
  animation: $val;
}
@mixin keyframes($name) {
  @-o-keyframes $name {
    @content;
  }
  @-moz-keyframes $name {
    @content;
  }
  @-webkit-keyframes $name {
    @content;
  }
  @keyframes $name {
    @content;
  }
}

@mixin scale($amount) {
  -moz-transform: scale($amount);
  -webkit-transform: scale($amount);
  -o-transform: scale($amount);
  -ms-transform: scale($amount);
  transform: scale($amount);
}

@mixin clearfix {
  // For modern browsers
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  // /* For IE 6/7 (trigger hasLayout) */
  // & {
  //   zoom:1;
  // }
}

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}
@mixin box-sizing($val) {
  -moz-box-sizing: $val;
  -webkit-box-sizing: $val;
  box-sizing: $val;
}

@mixin restricted-vw-property($prop, $size, $min-width, $max-width) {
  @media (min-width: #{$min-width + 1px}) and
      (max-width: #{$max-width - 1px}) {
    #{$prop}: #{$size + "vw"};
  }
  @media (max-width: $min-width) {
    #{$prop}: $min-width * $size / 100;
  }
  @media (min-width: $max-width) {
    #{$prop}: $max-width * $size / 100;
  }
}

@mixin vert-gradient($from, $to) {
  background: $from; /* Old browsers */
  background: -moz-linear-gradient(top, $from 0%, $to 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $from),
    color-stop(100%, $to)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $from 0%,
    $to 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $from 0%, $to 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $from 0%, $to 100%); /* IE10+ */
  background: linear-gradient(to bottom, $from 0%, $to 100%); /* W3C */
}

@mixin unfloat {
  display: block;
  float: none;
  width: auto;
}

@mixin user-select($val) {
  user-select: $val;
  -moz-user-select: $val;
  -webkit-user-select: $val;
  -ms-user-select: $val;
}

@mixin centred-children($inline: 0) {
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;

  // children should be inline-block columns
  @if ($inline) {
    display: -webkit-inline-flex;
    display: inline-flex;
  } @else {
    display: -webkit-flex;
    display: flex;
  }
}

@mixin centred-text($inline-block: false) {
  -webkit-justify-content: center;
  -webkit-flex-direction: column;
  justify-content: center;
  align-items: center;
  @if ($inline-block) {
    display: -webkit-inline-flex;
    display: inline-flex;
  } @else {
    display: -webkit-flex;
    display: flex;
  }
}

@mixin columns($val, $gap: null) {
  columns: $val;
  -moz-columns: $val;
  -webkit-columns: $val;

  @if $gap != null {
    column-gap: $gap;
    -moz-column-gap: $val;
    -webkit-column-gap: $gap;
  }
}

@mixin vw-fallback($prop, $val) {
  // set a vw/vh property with a preceding fallback based on a 960w viewport
  $fallback: 960px;

  #{$prop}: $val * $fallback / 100vw;
  #{$prop}: $val;
}

@mixin fullscreen {
  &.fullscreen {
    @content;
  }
  &:-webkit-full-screen {
    @content;
  }
  &:-moz-full-screen {
    @content;
  }
  &:fullscreen {
    @content;
  }
}

@mixin hardware-accelerated {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@mixin hover {
  &:hover {
    .no-touchevents &,
    .no-js & {
      @content;
    }
  }

  &.hover {
    @content;
  }
}
