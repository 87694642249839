article.wadeandleta-project.ajax-load {
  @include desktop-only {
    transform: translateY(0);

    .state-initialised & {
      @include transition(#{transform 0.75s});
    }
    &.project-transition-in,
    &.project-transition-out {
      transform: translateY(calc(100vh + #{$menu-button-height}));

      @media screen and (max-height: 900px) {
        transform: translateY(calc(100vh + #{$menu-button-height-900}));
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        transform: translateY(calc(100vh + #{$menu-button-height-1300}));
      }
      @media screen and (max-width: 1024px) {
        transform: translateY(calc(100vh + #{$menu-button-height-1024}));
      }
      @media screen and (min-height: 1415px) {
        transform: translateY(calc(100vh + #{$menu-button-height-1415}));
      }
    }
  }
  @include mobile-only {
    transform: translateY(0);

    .state-initialised & {
      @include transition(#{transform 0.75s});
    }
    &.project-transition-in,
    &.project-transition-out {
      transform: translateY(calc(100vh + #{$mobile-menu-button-height}));
    }
  }
}

.header-project-spacer {
  @include desktop-only {
    transform: translateY(0);
    @include transition(#{transform 0.75s});

    .state-studio-transition-in &,
    .state-studio-transition-out & {
      transform: translateY(100vh);
    }
  }
}


article.wadeandleta-studio.ajax-load {
  @include desktop-only {
    transform: translateY(0);

    .state-initialised & {
      @include transition(#{transform 0.75s});
    }
    .state-studio-transition-in &,
    .state-studio-transition-out & {
      transform: translateY(calc(100vh + #{$menu-button-height}));

      @media screen and (max-height: 900px) {
        transform: translateY(calc(100vh + #{$menu-button-height-900}));
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        transform: translateY(calc(100vh + #{$menu-button-height-1300}));
      }
      @media screen and (max-width: 1024px) {
        transform: translateY(calc(100vh + #{$menu-button-height-1024}));
      }
      @media screen and (min-height: 1415px) {
        transform: translateY(calc(100vh + #{$menu-button-height-1415}));
      }
    }
  }
  @include mobile-only {
    transform: translateY(0);

    .state-initialised & {
      @include transition(#{transform 0.75s});
    }
    .state-studio-transition-in &,
    .state-studio-transition-out & {
      transform: translateY(calc(100vh + #{$mobile-menu-button-height}));
    }
  }
}
