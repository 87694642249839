article.wadeandleta-studio {
  background: $dark-blue;

  h1 {
    @include sohne(220px);
    font-size: 15.4vw;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: -2.44px;
    text-transform: uppercase;

    @include mobile-only {
      font-size: 21vw;
    }
    &:nth-child(4) {
      text-align: center;
    }
  }
  h2 {
    @include sohne(68px);
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
  }
  a {
    color: $black;
    text-decoration: none;
  }
  .intro-wrap {
    .intro-title {
      text-align: center;
      @include sohne(68px);
      font-weight: 600;
    }
    .intro-text {
      @include sohne(18px);
      > * {
        max-width: 30em;
      }
      blockquote {
        font-weight: bold;
        margin-left: 0;
        margin-right: 0;
        @include sohne(24px);
      }
    }
  }
  .person-list-wrap {
    .person-listing {
      h2 {
        @include sohne(68px);
        font-weight: 600;
        line-height: 1;
      }
      .text {
        @include sohne(18px);
        > * {
          max-width: 30em;
        }
      }
      .video-wrap {
        position: relative;
        margin-bottom: -10% !important;
        &.person-1 {
          margin-top: -13%;
        }
        &.person-2 {
          margin-top: -10%;
        }
      }
      .videoplayer-inner .video {
        position: relative !important;
        background-color: transparent !important;
      }
    }
  }
  .block-list {
    .block {
      &.left {
        text-align: left;
      }
      &.right {
        text-align: right;
      }
      .text {
        @include sohne(16px);
        font-weight: 400;
        line-height: 1.45;

        strong {
          font-weight: 900;
        }
        
      }
    }
  }
  .slide-wrap {
    .controls-wrap {
      span {
        @include sohne(16px);
        font-weight: 400;
        line-height: 1.45;
        cursor: pointer;
        @include user-select(none);
      }
    }
  }
  .contact-wrap {
    @include mobile-only {
      background: $rose;

      .text {
        @include sohne(14px);
        font-weight: 400;
        line-height: 1.15;
      }
    }
    .text {
      p {
        margin: 0;
      }
    }
  }
  .studio-image-inner {
    .bkg {
      background: $yellow;
    }
  }
  .studio-inner {
    .bkg {
      background: $purple;
    }
  }
  @include desktop-only {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
    height: 100vh;
    overflow: hidden;
    padding-top: #{$menu-button-height};

    @media screen and (max-height: 900px) {
      padding-top: #{$menu-button-height-900};
    }
    @media screen and (min-height: 901px) and (max-height: 1414px) {
      padding-top: #{$menu-button-height-1300};
    }
    @media screen and (max-width: 1024px) {
      padding-top: #{$menu-button-height-1024};
    }
    @media screen and (min-height: 1415px) {
      padding-top: #{$menu-button-height-1415};
    }

    padding-right: $scrollbar-width;

    &.scroll {
      .state-studio-open & {
        overflow-y: auto;
        padding-right: 0;
      }

      &::-webkit-scrollbar {
        width: $scrollbar-width;
      }
      &::-webkit-scrollbar-track {
        @include scrollbar-track;

      }
      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
      }
    }
    h2 {
      margin-bottom: 0.67em;

      p {
        margin: 0;
      }
    }
    .page-title-wrap {
      @include ib-grid(17);
      @include prefix(.5);
      @include suffix(.5);
      margin-bottom: 55px;
      pointer-events: none;

      h1 {
        margin: 0;
        padding-right: $header-contact-width;

        @media screen and (min-width: 2200px) {
          padding-right: $header-contact-width-2200;
        }
        p {
          margin: 0;

          &:nth-child(3) {
            text-align: right;
          }
          &:nth-child(4) {
            @include ib-grid(11);
            @include prefix(5);
          }
        }
      }
    }
    .studio-image-inner {
      position: relative;
      padding: 2.77778% 0 2.77778%;
      .bkg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .intro-image-wrap {
        @include prefix(.5);
        @include suffix(1.75);
      }
    }
    .studio-inner {
      position: relative;
      padding: 100px 0 calc(50vh + 25px);

      .bkg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .intro-wrap {
        @include ib-grid(14.5);
        @include prefix(1.75);
        @include suffix(1.75);
        margin-bottom: 185px;
        .intro-text {
          margin-left: 50%;
          padding-left: 2%;
        }
      }
      .person-list-wrap {
        @include ib-grid(14.5);
        @include prefix(1.75);
        @include suffix(1.75);

        .person-listing {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 185px;
          h2 {
            width: 100%;
          }

          .text {
            width: 50%;
            padding-left: 2%;
          }

          .video-wrap {
            width: 50%;
          }
          
          // .canvas-wrap {
          //   @include ib-grid(13.5, 14.5);
          //   @include prefix(.5, 14.5);
          //   @include suffix(.5, 14.5);

          //   .state-page-resizing & {
          //     visibility: hidden;
          //   }
          //   .state-studio-transitioning & {
          //     visibility: inherit;
          //   }
          //   .canvas-inner {
          //     position: relative;
          //     padding-top: 100%;

          //     canvas {
          //       position: absolute;
          //       width: 100% !important;
          //       height: 100% !important;
          //       top: 0;
          //       left: 0;
          //     }
          //   }
          // }
        }
      }
      .press-wrap,
      .clients-wrap,
      .services-wrap,
      .speaking-wrap {
        margin-bottom: 115px;

        h2 {
          @include ib-grid(14.5);
          @include prefix(1.75);
          @include suffix(1.75);
          > * {
            max-width: 10em;
            margin: 0 auto;
            text-align: center;
          }
        }
        .block-list {
          @include ib-grid(14.5);
          @include prefix(1.75);
          @include suffix(1.75);

          .block {
            @include ib-grid(3.25, 14.5);
            @include suffix(.5, 14.5);

            &:nth-child(4n+4) {
              margin-right: 0;
            }
          }
        }
      }
      // .services-wrap,
      // .speaking-wrap {
      //   @include ib-grid(8);
      //   margin-bottom: 115px;

      //   .block-list {
      //     @include ib-grid(6.5, 8);
      //     @include prefix(.75, 8);

      //     .block {
      //       @include ib-grid(3.25, 6.5);
      //     }
      //   }
      // }
      // .services-wrap {
      //   @include prefix(1)
      // }
      // .speaking-wrap {
      //   position: relative;
      //   // @include suffix(1)

      //   .slide-wrap {
      //     &.has-previous {
      //       .controls-wrap {
      //         & > span.left {
      //           visibility: inherit;
      //         }
      //       }
      //     }
      //     &.has-next {
      //       .controls-wrap {
      //         & > span.right {
      //           visibility: inherit;
      //         }
      //       }
      //     }
      //     .controls-wrap {
      //       & > span {
      //         position: absolute;
      //         top: 50%;
      //         visibility: hidden;

      //         &.left {
      //           left: percentage(.25/9);
      //         }
      //         &.right {
      //           right: percentage(.25/9);
      //         }
      //       }
      //     }
      //     .slide-list {
      //       position: absolute;
      //       visibility: hidden;
      //       bottom: 0;
      //       pointer-events: none;
      //     }
      //   }
      // }
      .contact-wrap {
        // @include ib-grid(8);
        @include prefix(9.25);
        // @include suffix(5);

        .text-wrap {
          @include ib-grid(3.8, 9.25);
          @include suffix(.25, 9.25);

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }
      }
    }
  }
  @include mobile-only {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
    padding-top: #{$mobile-menu-button-height + 30px};
    overflow: hidden;

    #content & {
      height: 100vh;
      min-height: auto;
    }
    &.scroll {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    h2 {
      margin-bottom: 15px;

      p {
        margin: 0;
      }
    }
    .page-title-wrap {
      @include ib-grid(17);
      @include prefix(.5);
      @include suffix(.5);
      margin-bottom: 25px;
      pointer-events: none;

      h1 {
        margin: 0;

        p {
          margin: 0;

          &:nth-child(3) {
            text-align: right;
          }
          &:nth-child(4) {
            @include ib-grid(15);
            @include prefix(2, 17);
          }
        }
      }
    }
    .contact-wrap {
      padding: 35px 0 90px;

      .text-wrap {
        @include ib-grid(15);
        @include prefix(1.5);
        @include suffix(1.5);
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .studio-image-inner {
      position: relative;
      
      padding: 2.77778%;

      .bkg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }
    .studio-inner {
      position: relative;
      padding: 45px 0 185px;

      .bkg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .intro-wrap {
        @include ib-grid(15);
        @include prefix(1.5);
        @include suffix(1.5);
        margin-bottom: 165px;
      }
      .person-list-wrap {
        margin-bottom: 75px;

        .person-listing {
          position: relative;
          margin-bottom: 75px;
          @include prefix(1.5);
          @include suffix(1.5);

          &:last-child {
            margin-bottom: 0;
          }
          h2 {
            @include ib-grid(16.5);
            @include prefix(.75);
            @include suffix(.75);
          }
          .text {
          
          }
          .video-wrap {
            margin-bottom: 15px;
          }
          // .canvas-wrap {
          //   width: 100%;
          //   @include ib-grid(22);
          //   @include prefix(-2);

          //   .state-page-resizing & {
          //     visibility: hidden;
          //   }
          //   .state-studio-transitioning & {
          //     visibility: inherit;
          //   }
          //   .canvas-inner {
          //     position: relative;
          //     padding-top: 100%;

          //     canvas {
          //       position: absolute;
          //       width: 100% !important;
          //       height: 100% !important;
          //       top: 0;
          //       left: 0;
          //     }
          //   }
          // }
        }
      }

      .clients-wrap,
      .services-wrap,
      .speaking-wrap {
        position: relative;
        @include ib-grid(14);
        @include prefix(2);
        @include suffix(2);
        margin-bottom: 120px;

        .slide-wrap,
        .block-list {
          position: absolute;
          width: 100%;
          visibility: hidden;
          pointer-events: none;
        }
        .block-list {
          .block {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
          }
        }
        .view-wrap {
          text-align: center;
        }
        .control-wrap {
          position: absolute;
          @include sohne(18px);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;

          .left,
          .right {
            position: absolute;
            @include ib-grid(2, 14);
            top: 50%;
            pointer-events: all;
            text-align: center;
            padding: 10px 0;
          }
          .left {
            left: 0;
            transform: translate3d(-100%, -50%, 0);
          }
          .right {
            right: 0;
            transform: translate3d(100%, -50%, 0);
          }
        }
      }
      .speaking-wrap {
        margin-bottom: 0;
      }
    }
  }
}



article.wadeandleta-studio-OLD {
  background: $dark-blue;

  h1 {
    @include sohne(220px);
    font-size: 15.4vw;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: -2.44px;
    text-transform: uppercase;

    @include mobile-only {
      font-size: 21vw;
    }
    &:nth-child(4) {
      text-align: center;
    }
  }
  h2 {
    @include sohne(68px);
    font-weight: 700;
    line-height: 1.16;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
  }
  a {
    color: $black;
    text-decoration: none;
  }
  .intro-wrap {
    @include sohne(48px, $mobile-font-max: 16px);
  }
  .person-list-wrap {
    .person-listing {
      h2 {
        @include sohne(138px, $mobile-font-max: 50px);
        font-weight: 600;
        line-height: 1;
      }
    }
  }
  .block-list {
    .block {
      &.left {
        text-align: left;
      }
      &.right {
        text-align: right;
      }
      .text {
        @include sohne(16px);
        font-weight: 400;
        line-height: 1.45;
      }
    }
  }
  .slide-wrap {
    .controls-wrap {
      span {
        @include sohne(16px);
        font-weight: 400;
        line-height: 1.45;
        cursor: pointer;
        @include user-select(none);
      }
    }
  }
  .contact-wrap {
    @include mobile-only {
      background: $rose;

      .text {
        @include sohne(14px);
        font-weight: 400;
        line-height: 1.15;
      }
    }
    .text {
      p {
        margin: 0;
      }
    }
  }
  .studio-inner {
    .bkg {
      background: $yellow;
    }
  }
  @include desktop-only {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
    height: 100vh;
    overflow: hidden;
    padding-top: #{$menu-button-height};

    @media screen and (max-height: 900px) {
      padding-top: #{$menu-button-height-900};
    }
    @media screen and (min-height: 901px) and (max-height: 1414px) {
      padding-top: #{$menu-button-height-1300};
    }
    @media screen and (max-width: 1024px) {
      padding-top: #{$menu-button-height-1024};
    }
    @media screen and (min-height: 1415px) {
      padding-top: #{$menu-button-height-1415};
    }

    padding-right: $scrollbar-width;

    &.scroll {
      .state-studio-open & {
        overflow-y: auto;
        padding-right: 0;
      }

      &::-webkit-scrollbar {
        width: $scrollbar-width;
      }
      &::-webkit-scrollbar-track {
        @include scrollbar-track;

      }
      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
      }
    }
    h2 {
      margin-bottom: 25px;

      p {
        margin: 0;
      }
    }
    .page-title-wrap {
      @include ib-grid(17);
      @include prefix(.5);
      @include suffix(.5);
      margin-bottom: 55px;
      pointer-events: none;

      h1 {
        margin: 0;
        padding-right: $header-contact-width;

        @media screen and (min-width: 2200px) {
          padding-right: $header-contact-width-2200;
        }
        p {
          margin: 0;

          &:nth-child(3) {
            text-align: right;
          }
          &:nth-child(4) {
            @include ib-grid(11);
            @include prefix(5);
          }
        }
      }
    }
    .studio-inner {
      position: relative;
      padding: 100px 0 calc(50vh + 25px);

      .bkg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .intro-wrap {
        @include ib-grid(14.5);
        @include prefix(1.75);
        @include suffix(1.75);
        margin-bottom: 185px;
      }
      .person-list-wrap {
        @include ib-grid(14.5);
        @include prefix(1.75);
        @include suffix(1.75);

        .person-listing {
          position: relative;

          .text,
          .image-wrap {
            display: none;
          }
          .canvas-wrap {
            @include ib-grid(13.5, 14.5);
            @include prefix(.5, 14.5);
            @include suffix(.5, 14.5);

            .state-page-resizing & {
              visibility: hidden;
            }
            .state-studio-transitioning & {
              visibility: inherit;
            }
            .canvas-inner {
              position: relative;
              padding-top: 100%;

              canvas {
                position: absolute;
                width: 100% !important;
                height: 100% !important;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
      .clients-wrap {
        margin-bottom: 115px;

        h2 {
          @include ib-grid(14.5);
          @include prefix(1.75);
          @include suffix(1.75);
        }
        .block-list {
          @include ib-grid(14.5);
          @include prefix(1.75);
          @include suffix(1.75);

          .block {
            @include ib-grid(3.25, 14.5);
            @include suffix(.5, 14.5);

            &:nth-child(4n+4) {
              margin-right: 0;
            }
          }
        }
      }
      .services-wrap,
      .speaking-wrap {
        @include ib-grid(8);
        margin-bottom: 115px;

        .block-list {
          @include ib-grid(6.5, 8);
          @include prefix(.75, 8);

          .block {
            @include ib-grid(3.25, 6.5);
          }
        }
      }
      .services-wrap {
        @include prefix(1)
      }
      .speaking-wrap {
        position: relative;
        @include suffix(1)

        .slide-wrap {
          &.has-previous {
            .controls-wrap {
              & > span.left {
                visibility: inherit;
              }
            }
          }
          &.has-next {
            .controls-wrap {
              & > span.right {
                visibility: inherit;
              }
            }
          }
          .controls-wrap {
            & > span {
              position: absolute;
              top: 50%;
              visibility: hidden;

              &.left {
                left: percentage(.25/9);
              }
              &.right {
                right: percentage(.25/9);
              }
            }
          }
          .slide-list {
            position: absolute;
            visibility: hidden;
            bottom: 0;
            pointer-events: none;
          }
        }
      }
      .contact-wrap {
        @include ib-grid(8);
        @include prefix(5);
        @include suffix(5);

        .text-wrap {
          @include ib-grid(2.5, 8);
          @include suffix(.25, 8);

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }
      }
    }
  }
  @include mobile-only {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
    padding-top: #{$mobile-menu-button-height + 30px};
    overflow: hidden;

    #content & {
      height: 100vh;
      min-height: auto;
    }
    &.scroll {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    h2 {
      margin-bottom: 15px;

      p {
        margin: 0;
      }
    }
    .page-title-wrap {
      @include ib-grid(17);
      @include prefix(.5);
      @include suffix(.5);
      margin-bottom: 25px;
      pointer-events: none;

      h1 {
        margin: 0;

        p {
          margin: 0;

          &:nth-child(3) {
            text-align: right;
          }
          &:nth-child(4) {
            @include ib-grid(15);
            @include prefix(2, 17);
          }
        }
      }
    }
    .contact-wrap {
      padding: 35px 0 90px;

      .text-wrap {
        @include ib-grid(15);
        @include prefix(1.5);
        @include suffix(1.5);
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .studio-inner {
      position: relative;
      padding: 45px 0 185px;

      .bkg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .intro-wrap {
        @include ib-grid(15);
        @include prefix(1.5);
        @include suffix(1.5);
        margin-bottom: 165px;
      }
      .person-list-wrap {
        margin-bottom: 75px;

        .person-listing {
          position: relative;
          margin-bottom: 75px;

          &:last-child {
            margin-bottom: 0;
          }
          h2 {
            @include ib-grid(16.5);
            @include prefix(.75);
            @include suffix(.75);
          }
          .text,
          .image-wrap {
            display: none;
          }
          .canvas-wrap {
            width: 100%;
            @include ib-grid(22);
            @include prefix(-2);

            .state-page-resizing & {
              visibility: hidden;
            }
            .state-studio-transitioning & {
              visibility: inherit;
            }
            .canvas-inner {
              position: relative;
              padding-top: 100%;

              canvas {
                position: absolute;
                width: 100% !important;
                height: 100% !important;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }

      .clients-wrap,
      .services-wrap,
      .speaking-wrap {
        position: relative;
        @include ib-grid(14);
        @include prefix(2);
        @include suffix(2);
        margin-bottom: 120px;

        .slide-wrap,
        .block-list {
          position: absolute;
          width: 100%;
          visibility: hidden;
          pointer-events: none;
        }
        .block-list {
          .block {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
          }
        }
        .view-wrap {
          text-align: center;
        }
        .control-wrap {
          position: absolute;
          @include sohne(18px);
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;

          .left,
          .right {
            position: absolute;
            @include ib-grid(2, 14);
            top: 50%;
            pointer-events: all;
            text-align: center;
            padding: 10px 0;
          }
          .left {
            left: 0;
            transform: translate3d(-100%, -50%, 0);
          }
          .right {
            right: 0;
            transform: translate3d(100%, -50%, 0);
          }
        }
      }
      .speaking-wrap {
        margin-bottom: 0;
      }
    }
  }
}

.wadeandleta-404 {
  .title-wrap {
    h1 {
      @include sohne(220px);
      font-size: 15.4vw;
      font-weight: 500;
      line-height: 1.1;
      letter-spacing: -2.44px;
      text-transform: uppercase;

      // @include desktop-only {
      //   @media screen and (max-height: 900px) {
      //     @include sohne(140px);
      //     line-height: 1.1;
      //   }
      //   @media screen and (min-height: 901px) and (max-height: 1414px) {
      //     @include sohne(180px);
      //     line-height: 1.1;
      //   }
      //   // iPad pro
      //   @media screen and (max-width: 1024px) {
      //     @include sohne(140px);
      //     line-height: 1.1;
      //   }
      //   @media screen and (min-height: 1415px) {
      //     @include sohne(220px);
      //     line-height: 1.1;
      //   }
      // }
      @include mobile-only {
        font-size: 15.4vw;
      }
      &:nth-child(4) {
        text-align: center;
      }
    }
  }
  @include desktop-only {
    .title-wrap {
      position: fixed;
      width: 100%;
      height: calc(100vh - #{$menu-button-height});
      top: $menu-button-height;
      left: 0;

      @media screen and (max-height: 900px) {
        top: $menu-button-height-900;
        height: calc(100vh - #{$menu-button-height-900});
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        top: $menu-button-height-1300;
        height: calc(100vh - #{$menu-button-height-1300});
      }
      @media screen and (max-width: 1024px) {
        top: $menu-button-height-1024;
        height: calc(100vh - #{$menu-button-height-1024});
      }
      @media screen and (min-height: 1415px) {
        top: $menu-button-height-1415;
        height: calc(100vh - #{$menu-button-height-1415});
      }

      h1 {
        margin: 0;
      }
      .bottom-wrap {
        position: absolute;
        width: 100%;
        bottom: 0;

        h1 {
          &:nth-child(1) {
            padding-right: $header-contact-width;
            text-align: right;

            @media screen and (min-width: 2200px) {
              padding-right: $header-contact-width-2200;
            }
          }
          &:nth-child(2) {
            @include ib-grid(11);
            @include prefix(5);
          }
        }
      }
    }
    .image-wrap {
      position: fixed;
      @include ib-grid(3.5);
      top: calc((100vh - #{$menu-button-height}) / 2 + #{$menu-button-height});
      left: calc(50% - 50px);
      transform: translate3d(-50%, -50%, 0);

      @media screen and (max-height: 900px) {
        top: calc((100vh - #{$menu-button-height-900}) / 2 + #{$menu-button-height-900});
      }
      @media screen and (min-height: 901px) and (max-height: 1414px) {
        top: calc((100vh - #{$menu-button-height-1300}) / 2 + #{$menu-button-height-1300});
      }
      @media screen and (max-width: 1024px) {
        top: calc((100vh - #{$menu-button-height-1024}) / 2 + #{$menu-button-height-1024});
      }
      @media screen and (min-height: 1415px) {
        top: calc((100vh - #{$menu-button-height-1415}) / 2 + #{$menu-button-height-1415});
      }
    }
  }
  @include mobile-only {
    padding-top: $mobile-menu-button-height;

    .title-wrap {
      @include ib-grid(17);
      @include prefix(.5);
      @include suffix(.5);
      margin-top: 30px;
      margin-bottom: 25px;
      pointer-events: none;

      h1 {
        margin: 0;

        p {
          margin: 0;
        }
      }
      .bottom-wrap {
        h1 {
          &:nth-child(1) {
            text-align: right;
          }
          &:nth-child(2) {
            @include ib-grid(15, 17);
            @include prefix(2, 17);
          }
        }
      }
    }
    .image-wrap {
      @include ib-grid(15);
      @include prefix(1.5);
      @include suffix(1.5);
      left: 0;
      z-index: -1;
    }
  }
}
