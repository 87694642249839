$base-path: './';

@mixin webfont($name, $filename, $weight: 400, $style: normal, $woff2: false) {
  // see https://css-tricks.com/snippets/css/using-font-face/
  @font-face {
    font-family: "#{$name}";
    // src: url($base-path + $filename + '.eot'); // IE9
    // @if $woff2 {
    //   src: url($base-path + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    //        url($base-path + $filename + '.woff2'), // advanced browsers
    //        url($base-path + $filename + '.woff') format('woff'); // other proper browsers
    // } @else {
    //   src: url($base-path + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    //        url($base-path + $filename + '.woff') format('woff'); // other proper browsers
    // }

    src: url($base-path + $filename + '.woff2'), // advanced browsers
         url($base-path + $filename + '.woff') format('woff'); // other proper browsers
    font-weight: $weight;
    font-style: $style;
  }
}

@include webfont('Sohne', 'SohneWeb-Medium', 700);
@include webfont('Sohne', 'SohneWeb-Regular', 400);
